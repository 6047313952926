import { DashboardLayout, PopupModal } from "../Components";
import { BillsComponent } from "../Components/navbar";
import Select from 'react-select';
import { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig";
import { AiOutlineFilePdf, AiOutlineFileExcel } from "react-icons/ai";
import axios from "axios";

const Ledger = () => {

    const [clients, setClients] = useState([]);
    const [popupType, setpopupType] = useState("");
    const [error, SetError] = useState("")

    function getCurrentMonth() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}`;

        return formattedDate;
    }

    const [filterData, setFilterData] = useState({
        period_from: getCurrentMonth(),
        period_to: getCurrentMonth(),
        client: ''
    })
    const GetClients = async () => {
        try {
            const response = await axiosConfig.get(`/clients/clients/?page_size=1000&is_suspended=false`);
            setClients(response.data.results)
        } catch (error) {
            console.log(error);
        }
    }

    const handlePdf = async () => {
        try {
            if (!filterData.client) {
                alert("Please select a client");
                return;
            }
            const response = await axiosConfig.get(`accounts/sanry_ledger_pdf/`, {
                params: {
                    period_from: filterData.period_from || '',
                    period_to: filterData.period_to || '',
                    client: filterData.client || ''
                }
            });
            const pdfUrl = response.data?.pdf_url || `${axiosConfig.defaults.baseURL}accounts/sanry_ledger_pdf/?period_from=${filterData.period_from || ''}&period_to=${filterData.period_to || ''}&client=${filterData.client || ''}`;
            window.open(pdfUrl, '_blank');
        } catch (error) {
            console.error(error);
            setpopupType("error");
            SetError(error.response?.data?.error || "An error occurred while generating the PDF");
        }
    };

    const handleExcel = async() => {
        try {
            if (!filterData.client) {
                alert("Please select a client");
                return;
            }
            await axiosConfig.get(`accounts/sanry_ledger_csv/?client=${filterData.client||''}&period_from=${filterData.period_from}&period_to=${filterData.period_to}`)
            const excelUrl = `${axiosConfig.defaults.baseURL}accounts/sanry_ledger_csv/?client=${filterData.client||''}&period_from=${filterData.period_from}&period_to=${filterData.period_to}`;
            window.open(excelUrl, '_blank');
        } catch (error) {
            console.log(error)
            setpopupType("error");
            SetError(error.response?.data?.error || "An error occurred while generating the Excel");
        }
    }


    useEffect(() => {
        GetClients();
    }, [])
    return (
        <>
            <DashboardLayout>
                <div className="child-sidenav">
                    <BillsComponent />
                    <div className="childnav-content">
                        <div className="row">
                            <div className="col-5">
                                <Select
                                    placeholder="Client"
                                    className="flex-1"
                                    options={clients.map((res) => ({ "value": res.id, "label": res.client_name }))}
                                    onChange={(selectedOption) => setFilterData(prev => ({ ...prev, client: selectedOption.value }))}
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    type="month"
                                    className="form-control"
                                    name="period_from"
                                    value={filterData.period_from}
                                    onChange={(e) => setFilterData(prev => ({ ...prev, period_from: e.target.value }))}
                                />
                            </div>
                            <div className="col-3">
                                <input
                                    type="month"
                                    className="form-control"
                                    name="period_to"
                                    value={filterData.period_to}
                                    onChange={(e) => setFilterData(prev => ({ ...prev, period_to: e.target.value }))}
                                />
                            </div>
                            <div className="rowEndCenter gap-10 col-1">
                                <AiOutlineFileExcel
                                    className="report-icons"
                                    size={22}
                                    style={{ flexShrink: 0 }}
                                    onClick={handleExcel}
                                />
                                <AiOutlineFilePdf
                                    className="report-icons"
                                    size={22}
                                    style={{ flexShrink: 0 }}
                                    onClick={handlePdf}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            {popupType !== "" &&
                <PopupModal setpopupType={setpopupType} popupType={popupType} error={error} />
            }
        </>
    )
}

export { Ledger }