import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaFileInvoice } from "react-icons/fa";
import { SiContactlesspayment } from "react-icons/si";
import { MdOutlinePendingActions, MdOutlineNoteAlt } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { PiBookBookmarkThin } from "react-icons/pi";
const BillsComponent = () => {
    let newDate = new Date()
let Newmonth = newDate.getMonth()
let previousMonth = Newmonth+1;
let year =newDate.getFullYear();
if(previousMonth <= 9){
    Newmonth = "0"+previousMonth
}
else{
    Newmonth=previousMonth 
}
    return (
        <div className="categorie-header">
            <NavLink to={`/dashboard/masters/client_bills/?month=${year}-${Newmonth}`} className="categorie-subtitle" ><div className="child-title">
                <SiContactlesspayment size={24} className="childnav-icon" />
                <p className="childnav-name">Client Bills</p>
            </div></NavLink>
            <NavLink to={`/dashboard/masters/ledger`} className="categorie-subtitle" ><div className="child-title">
                <PiBookBookmarkThin size={24} className="childnav-icon" />
                <p className="childnav-name">Ledger</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/payment_invoices" className="categorie-subtitle" ><div className="child-title">
                <FaFileInvoice size={24} className="childnav-icon" />
                <p className="childnav-name">Payments</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/outstandings" className="categorie-subtitle" ><div className="child-title">
                <MdOutlinePendingActions size={24} className="childnav-icon" />
                <p className="childnav-name">Outstandings</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/expenditure" className="categorie-subtitle" ><div className="child-title">
                <GiMoneyStack size={24} className="childnav-icon" />
                <p className="childnav-name">Expenditures</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/grn" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineNoteAlt size={24} className="childnav-icon" />
                <p className="childnav-name">GRN</p>
            </div></NavLink>
        </div>
    )
}

export  {BillsComponent}
